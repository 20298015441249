/**
 *  * Rôle : Loader Data
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React from 'react';
import { PulseLoader } from 'react-spinners';

const Loader = (props) => {
	let { size, color } = props;

	if (size === undefined) {
		size = '8px';
	}
	if (color === undefined) {
		color = '#352b67';
	}

	return <PulseLoader color={color} size={size} />;
};

export default Loader;
