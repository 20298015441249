/**
 *  * Rôle : Module titre des pages
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React from 'react';
import H1 from '../functional/H1';

const Title = (props) => {
	const { children } = props;
	return (
		<div className="py-10">
			<H1 colorTitle="white">{children}</H1>
		</div>
	);
};

export default Title;
