/**
 *  * Rôle : Menu secondaire de navigation
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import { Link } from 'gatsby';
import React from 'react';

const UnderMenu = () => {
	return (
		<div className="bg-primary p-5 -mt-12 text-white font-medium flex items-center justify-center">
			<Link
				to="/videos"
				className="flex items-center justify-center border-r border-secondary border-opacity-50 p-5 hover:bg-secondary hover:bg-opacity-10 animate"
			>
				<svg
					className="hidden sm:inline-block h-5 w-5 mr-2"
					viewBox="0 0 24 24"
					fill="none"
					stroke="currentColor"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				>
					<polygon points="23 7 16 12 23 17 23 7" />{' '}
					<rect x="1" y="5" width="15" height="14" rx="2" ry="2" />
				</svg>
				<span className="text-xs md:text-base">Vidéos</span>
			</Link>
			<Link
				to="/audios"
				className="flex items-center justify-center border-r border-secondary border-opacity-50 p-5 hover:bg-secondary hover:bg-opacity-10 animate"
			>
				<svg
					className="hidden sm:inline-block h-5 w-5 mr-2"
					viewBox="0 0 24 24"
					fill="none"
					stroke="currentColor"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z"
					/>
				</svg>
				<span className="text-xs md:text-base">Audios</span>
			</Link>
			<Link
				to="/coran"
				className="flex items-center justify-center border-r border-secondary border-opacity-50 p-5 hover:bg-secondary hover:bg-opacity-10 animate"
			>
				<svg
					className="hidden sm:inline-block h-5 w-5 mr-2"
					viewBox="0 0 24 24"
					fill="none"
					stroke="currentColor"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				>
					<path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />{' '}
					<polyline points="14 2 14 8 20 8" /> <line x1="16" y1="13" x2="8" y2="13" />{' '}
					<line x1="16" y1="17" x2="8" y2="17" /> <polyline points="10 9 9 9 8 9" />
				</svg>
				<span className="text-xs md:text-base">Coran</span>
			</Link>
			<Link
				to="https://app.dourous.net/"
				className="flex items-center justify-center p-5 hover:bg-secondary hover:bg-opacity-10 animate"
			>
				<svg
					className="hidden sm:inline-block h-5 w-5 mr-2"
					viewBox="0 0 24 24"
					fill="none"
					stroke="currentColor"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				>
					<path stroke="none" d="M0 0h24v24H0z" />{' '}
					<rect x="3" y="4" width="18" height="12" rx="1" />{' '}
					<line x1="7" y1="20" x2="17" y2="20" /> <line x1="9" y1="16" x2="9" y2="20" />{' '}
					<line x1="15" y1="16" x2="15" y2="20" />
				</svg>
				<span className="text-xs md:text-base hidden md:inline">Cours en ligne</span>
				<span className="text-xs md:text-base inline md:hidden">Cours</span>
			</Link>
		</div>
	);
};

export default UnderMenu;
