/**
 *  * Rôle : Module d'entête par défaut
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React from 'react';
// import { Link } from "gatsby"
import flowers from '../../images/svg/flowers-bg.svg';
import Menu from './Menu';

const SimpleHeader = () => {
	return (
		<>
			<header className="header relative">
				<div
					className="absolute z-0 w-full h-full opacity-20"
					style={{ backgroundImage: `url(${flowers})`, backgroundSize: '30%' }}
				></div>

				<div className="relative z-20">
					<Menu shadow={true} />
				</div>
			</header>
		</>
	);
};

export default SimpleHeader;
