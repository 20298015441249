/**
 *  * Rôle : Affiche une fenêtre modale du menu en version responsive
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React from 'react';
import { Transition } from '@headlessui/react';
import { Link } from 'gatsby';

export default function Drawer(props) {
	const { state, listenChild } = props;

	const closeWindow = () => {
		listenChild(false);
	};

	return (
		<Transition
			show={state}
			enter="transition-opacity duration-7505"
			enterFrom="opacity-0"
			enterTo="opacity-100"
			leave="transition-opacity duration-150"
			leaveFrom="opacity-100"
			leaveTo="opacity-0"
		>
			<div
				className="fixed left-0 w-screen h-screen z-50"
				style={{ backgroundColor: 'rgba(53, 43, 103, 0.97)' }}
			>
				<button
					onClick={closeWindow}
					aria-label="Close panel"
					className="fixed top-8 right-4 bg-secondary hover:bg-opacity-50 p-1 rounded-full text-white flex items-center justify-center animate"
				>
					<svg
						className="h-5 w-5"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M6 18L18 6M6 6l12 12"
						/>
					</svg>
				</button>

				<div className="text-xl font-bold text-white uppercase flex flex-col items-center justify-center h-screen">
					<Link
						activeClassName="active"
						to="/"
						className="mb-5 hover:text-secondary animate"
					>
						Accueil
					</Link>
					<Link
						activeClassName="active"
						to="/videos"
						className="mb-5 hover:text-secondary animate"
					>
						A propos
					</Link>
					<Link
						activeClassName="active"
						to="/videos"
						className="mb-5 hover:text-secondary animate"
					>
						Vidéos
					</Link>
					<Link
						activeClassName="active"
						to="/audios"
						className="mb-5 hover:text-secondary animate"
					>
						Audios
					</Link>
					<Link
						activeClassName="active"
						to="/search"
						className="mb-5 hover:text-secondary animate"
					>
						Recherche
					</Link>
					<Link
						activeClassName="active"
						to="https://app.dourous.net/"
						className="mb-5 hover:text-secondary animate"
					>
						Membres
					</Link>
				</div>
			</div>
		</Transition>
	);
}
