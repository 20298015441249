/**
 *  * Rôle : Button
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React from 'react';

const Button = (props) => {
	const { children, rounded, className } = props;

	return (
		<button
			className={`${className} flex items-center justify-center bg-secondary hover:bg-opacity-50 ${
				rounded ? rounded : 'rounded-sm'
			} px-6 py-3 text-white uppercase text-xs hover-ride-top focus:outline-none animate`}
		>
			{children}
		</button>
	);
};

export default Button;
