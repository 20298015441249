/**
 *  * Rôle : Logo du projet
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React from 'react';
import { Link } from 'gatsby';
import LogoWebSiteForBgSombre from '../../../images/svg/Logo-bg-sombre.svg';
import LogoWebSiteForBgClair from '../../../images/svg/Logo-bg-clair.svg';

const Logo = (props) => {
	return (
		<Link to="/" className="border-none">
			{props.bg === 'clair' ? (
				<img src={LogoWebSiteForBgClair} className="w-32" alt="" />
			) : (
				<img src={LogoWebSiteForBgSombre} className="w-32" alt="" />
			)}
		</Link>
	);
};

export default Logo;
