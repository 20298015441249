/**
 * Menu
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React, { useState } from 'react';
import { Link } from 'gatsby';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Logo from '../../images/svg/Logo-Dourous-Net.svg';
// import NavSocial from '../marketing/social/NavSocial'
import Container from './Container';
import MenuResponsive from './MenuResponsive';

const Menu = (props) => {
	let { shadow } = props;

	if (shadow) {
		shadow = 'rgba(0, 0, 0, 0.24) 0px 10px 10px';
	}

	const [drawer, setDrawer] = useState(false);
	const listenChild = (valueChild) => {
		setDrawer(!drawer);
	};
	return (
		<>
			<MenuResponsive state={drawer} listenChild={listenChild} />

			<div style={{ boxShadow: `${shadow}` }}>
				<Container className="flex items-center justify-between py-5">
					<div className="-mt-5">
						<Link to="/" className="border-none">
							<img src={Logo} className="w-32 mt-2" alt="" />
						</Link>
					</div>
					<div className="flex items-center justify-center">
						{/* Navigation Desktop */}
						<div className="hidden lg:block text-xxs lg:text-xs mr-2 text-white uppercase ">
							<Link
								activeClassName="active"
								to="/"
								className="linkMenu mx-2 opacity-60 hover:opacity-100 hover:text-white animate"
							>
								accueil
							</Link>
							<Link
								activeClassName="active"
								to="/nader-abou-anas"
								className="linkMenu mx-2 opacity-60 hover:opacity-100 hover:text-white animate"
							>
								a propos
							</Link>
							<Link
								activeClassName="active"
								to="/videos"
								className="linkMenu mx-2 opacity-60 hover:opacity-100 hover:text-white animate"
							>
								vidéos
							</Link>
							<Link
								activeClassName="active"
								to="/audios"
								className="linkMenu mx-2 opacity-60 hover:opacity-100 hover:text-white animate"
							>
								audios
							</Link>
							{/* <Link
								activeClassName="active"
								to="/articles-blog"
								className="linkMenu mx-2 opacity-60 hover:opacity-100 hover:text-white animate"
							>
								articles
							</Link> */}
							{/* <Link
								activeClassName="active"
								to="/images-liste"
								className="linkMenu mx-2 opacity-60 hover:opacity-100 hover:text-white animate"
							>
								images
							</Link> */}
							{/* <Link
								activeClassName="active"
								to="https://app.dourous.net/"
								className="linkMenu mx-2 opacity-60 hover:opacity-100 hover:text-white animate"
							>
								cours en ligne
							</Link> */}
						</div>

						{/* Navigation Responsive */}
						<button
							onClick={() => setDrawer(!drawer)}
							className="lg:hidden border hover:border-gray-300 border-secondary rounded-full p-1 text-white hover:text-gray-300 animate"
						>
							<svg
								className="h-5 w-5"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								strokeWidth="2"
								stroke="currentColor"
								fill="none"
								strokeLinecap="round"
								strokeLinejoin="round"
							>
								<path stroke="none" d="M0 0h24v24H0z" />{' '}
								<line x1="4" y1="6" x2="20" y2="6" />{' '}
								<line x1="4" y1="12" x2="20" y2="12" />{' '}
								<line x1="4" y1="18" x2="20" y2="18" />
							</svg>
						</button>

						{/* Social & Search */}
						<div className="hidden lg:flex lg:items-center lg:justify-center text-white">
							<Link to="https://app.dourous.net/">
								<button className="uppercase text-white hover:bg-secondary font-medium px-3 py-2 rounded-xs flex items-center justify-center text-xxs animate">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="h-3 w-3"
										viewBox="0 0 20 20"
										fill="currentColor"
									>
										<path
											fillRule="evenodd"
											d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
											clipRule="evenodd"
										/>
									</svg>
									<span className="ml-1">Cours d'islam en ligne</span>
								</button>
							</Link>
							<Link
								to="/search/"
								className="ml-2 w-5 h-5 flex items-center justify-center"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-5 w-5"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
									/>
								</svg>
							</Link>
							{/* <NavSocial /> */}
						</div>
					</div>
				</Container>
			</div>
		</>
	);
};

export default Menu;
