/**
 *  * Rôle : Lien vers les fichier Audio du coran
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React from 'react';
import H2 from '../../../functional/H2';
import Illustration from '../../../../images/design/coran.jpg';
import { Link } from 'gatsby';

export default function Coran(props) {
	let { marginTop } = props;
	if (!marginTop) {
		marginTop = 'mt-5';
	}

	return (
		<div className={`w-full p-10 text-gray-500 ${marginTop}`}>
			<H2 colorTitle={props.theme === 'dark' ? '' : 'white'}>Coran</H2>
			<Link to="/coran" className="hover:opacity-90 animate">
				<img src={Illustration} title="Coran" alt="Coran" className="rounded-xl"></img>
			</Link>
		</div>
	);
}
