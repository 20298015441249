/**
 * Global Layout
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import HeroTop from './HeroTop';
import Header from './Header';
import '../../styles/layout.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
	fab,
	faFacebookF,
	faFacebook,
	faInstagram,
	faTwitter,
	faYoutube,
	faPinterestP
} from '@fortawesome/free-brands-svg-icons';

import {
	faAngleDown,
	faAngleUp,
	faCheck,
	faEnvelopeOpenText,
	faSearch,
	faShareAlt,
	faVenusMars,
	faVolumeUp
} from '@fortawesome/free-solid-svg-icons';
import Footer from './Footer';
import SimpleHeader from './SimpleHeader';
import { Link } from '@reach/router';

library.add(
	fab,
	faFacebookF,
	faFacebook,
	faYoutube,
	faInstagram,
	faTwitter,
	faPinterestP,
	faSearch,
	faShareAlt,
	faEnvelopeOpenText,
	faAngleDown,
	faAngleUp,
	faCheck,
	faVolumeUp,
	faVenusMars
);

const Layout = (props) => {
	useEffect(() => {
		// Gestion de la double scrollBar
		document.getElementsByTagName('html')[0].style.overflow = 'auto';
	}, []);

	let { children, content, header, title, animate } = props;

	if (header === 'heroTop') {
		header = <HeroTop siteTitle={title ?? `Title`} />;
	} else if (header === 'heroPost') {
		header = <SimpleHeader />;
	} else {
		header = <Header title={title} />;
	}

	// Animation Main
	let main;
	if (animate === false) {
		main = <main>{children}</main>;
	} else {
		main = (
			<main
			// data-aos="fade"
			>
				{children}
			</main>
		);
	}

	return (
		<>
			<div className="bg-primary text-white">
				<div className="max-w-5xl w-full mx-auto py-2 font-semibold text-xxs md:text-xs flex flex-col md:flex-row items-center justify-center gap-1 md:gap-5">
					<div>
						Visitez notre espace de cours d'islam en ligne enseigné par Nader Abou Anas
					</div>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						strokeWidth={2.5}
						stroke="currentColor"
						className="w-4 h-4 md:block hidden"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
						/>
					</svg>

					<a
						href="https://app.dourous.net/"
						className="text-white hover:underline font-bold underline"
					>
						Découvrir maintenant
					</a>
				</div>
			</div>
			<div id="layout" className="mx-auto my-0" style={{ maxWidth: `${content}` }}>
				{header}
				{main}
				<Footer />
			</div>
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired
};

export default Layout;
