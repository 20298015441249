/**
 *  * Rôle : Bannière Hero Top
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React from 'react';
import Photo from '../../images/design/nader-abou-anas-banner.png';
// import Logo from '../../images/svg/Logo-Nader-abou-anas-banner.svg'
import Logo from '../../images/svg/Logo-bg-sombre.svg';

const Banner = () => {
	return (
		<div className="flex flex-col md:flex-row items-center justify-between">
			<img
				src={Photo}
				alt=""
				className="mt-5 w-7/12 animate"
				// data-aos="fade"
				// data-aos-duration="1000"
			/>
			<div className="w-full flex items-start justify-center mr-4">
				<div
					className="flex flex-col itmes-center justify-center w-4/6 sm:w-3/6 md:w-2/4 animate"
					// data-aos="fade"
					// data-aos-duration="1000"
				>
					<img src={Logo} alt="" className="mt-5 pr-1 mb-3 w-full" />
					<div className="flex items-center justify-center w-full">
						<div className="bg-secondary w-10 h-1 mr-2"></div>
						<h2 className="text-white text-xs font-light text-center p-0 m-0">
							Imam, Conférencier et Enseignant
						</h2>
						<div className="bg-secondary w-10 h-1 ml-2"></div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Banner;
