/**
 *  * Rôle : Module de newsletter
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React, { useState } from 'react';
import axios from 'axios';
import Button from '../../functional/Button';
import H2 from '../../functional/H2';
import Loader from '../../functional/Loader';
import { Link } from '@reach/router';

const Newsletter = (props) => {
	const { align, description, type } = props;

	const [email, setEmail] = useState('');
	const [errorMessage, setErrorMessage] = useState(null);
	const [successMessage, setSuccessMessage] = useState(null);
	const [loading, setLoading] = useState(false);

	const signup = (e) => {
		e.preventDefault();
		setLoading(true);

		axios
			.get(
				`https://us-central1-dourousnet-3fcb0.cloudfunctions.net/signInMailingListe?email=${email}`
			)
			.then(function (res) {
				setErrorMessage(null);
				setSuccessMessage('Merci pour votre inscription');
				setLoading(false);

				setTimeout(function () {
					setSuccessMessage(null);
				}, 8000);
			})
			.catch(function (error) {
				setSuccessMessage(null);
				console.log(
					'Error SignInMailingListe',
					JSON.parse(error.response.data.error.response.text)
				);

				if (JSON.parse(error.response.data.error.response.text)) {
					if (
						JSON.parse(error.response.data.error.response.text).title ===
						'Invalid Resource'
					) {
						setErrorMessage("Cette adresse Email n'a pas l'air valide");
					} else if (
						JSON.parse(error.response.data.error.response.text).title ===
						'Member Exists'
					) {
						setErrorMessage('Vous êtes déjà inscrit !');
					} else {
						setErrorMessage('Une erreur est survenue, rechargez la page...');
					}
				}
				setLoading(false);

				setTimeout(function () {
					setErrorMessage(null);
				}, 9000);
			});
	};

	const button =
		email !== '' && !loading ? (
			<Button rounded={`${type === 'inline' ? 'rounded-r-full' : 'rounded-sm'}`}>
				Inscription
			</Button>
		) : (
			<Button rounded={`${type === 'inline' ? 'rounded-r-full' : 'rounded-sm'}`}>
				{loading ? <Loader color="#ffffff" size="5px" /> : 'Inscription'}{' '}
			</Button>
		);

	return (
		<div>
			<H2 colorTitle="white" align={align === 'center' ? 'center' : 'left'}>
				{' '}
				Newsletter{' '}
			</H2>

			{description === 'hidden' ? (
				<></>
			) : (
				<p className="mb-4 text-gray-400 text-center md:text-justify text-xxs leading-relaxed font-light">
					Inscrivez-vous à la newsletter pour être informé des prochaines vidéos
				</p>
			)}
			<form
				onSubmit={signup}
				method="POST"
				className={`${type === 'inline' ? 'flex items-center justify-center' : ''}`}
			>
				<input
					type="email"
					value={email}
					id="email"
					onChange={(e) => setEmail(e.target.value)}
					placeholder="Entrez votre adresse e-mail"
					className={`w-96 p-3 placeholder-gray-900 text-primary bg-white ${
						type === 'inline' ? 'rounded-l-full pl-5' : 'w-full rounded-sm '
					} text-xxs lg:text-md`}
				/>

				<div
					className={`${
						type === 'inline' ? 'rounded-3xl' : 'w-full md:mt-4 mt-6'
					} flex justify-center ${
						align === 'center' ? 'md:justify-center' : 'md:justify-start'
					}`}
				>
					{button}
				</div>
			</form>

			<div className="w-full flex flex-col items-center justify-center">
				{errorMessage ? (
					<div className="py-2 px-5 rounded-sm bg-red-600 bg-opacity-40 text-xxs text-center text-white">
						{errorMessage}
					</div>
				) : null}
				{successMessage ? (
					<div className="py-2 px-5 rounded-sm bg-green-600 bg-opacity-40 text-xxs text-center text-white">
						{successMessage}
					</div>
				) : null}
			</div>

			<div
				className={`mb-4 text-gray-400 ${
					align === 'center' ? 'text-center' : 'text-left'
				} text-xxs leading-relaxed font-light`}
			>
				En renseignant votre adresse e-mail,
				vous acceptez de recevoir des informations en provenance de dourous.net
				 par courrier électronique et vous prenez connaissance de notre{' '}
				<Link
					to="/politique-de-confidentialite"
					className="text-white font-semibold underline"
				>
					Politique de confidentialité
				</Link>
				.
			</div>
			<p
				className={`mb-4 text-gray-400 ${
					align === 'center' ? 'text-center' : 'text-left'
				} text-xxs leading-relaxed font-light`}
			>
				Il sera possible de vous désinscrire a tout moment en utilisant le lien de
				désinscription disponible dans chacun de nos courriers.
			</p>
		</div>
	);
};

export default Newsletter;
