/**
 * Container
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React from 'react';

const Container = (props) => {
	let { children, className, maxWidth, paddingNull } = props;

	if (!maxWidth) {
		maxWidth = 'max-w-5xl';
	}

	if (paddingNull) {
		paddingNull = 'px-0 sm:px-4';
	} else {
		paddingNull = 'px-2 sm:px-4';
	}

	return (
		<div
			className={`${className} mx-auto ${maxWidth} ${paddingNull} xl:px-0`}
			id="containerSelectorFocus"
		>
			{children}
		</div>
	);
};

export default Container;
