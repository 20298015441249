/**
 *  * Rôle : Footer page
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React from 'react';
import { Link } from 'gatsby';
import Container from './Container';
import Logo from '../marketing/logo/Logo';
import NavSocial from '../marketing/social/NavSocial';
import Newsletter from '../marketing/newsletter/Newsletter';
import Coran from '../marketing/sideBar/componentSideBar/Coran';

const Footer = () => {
	return (
		<footer className="">
			{/* Footer */}
			<div className="pt-5 pb-20 bg-primary">
				<Container>
					<div className="flex flex-col justify-between md:flex-row item-start">
						<div className="flex flex-col items-center w-full md:w-1/3 md:items-start">
							<Logo bg={'clair'} />
							<p className="my-4 text-xs font-light leading-relaxed text-center text-gray-400 md:text-justify">
								Le frère Nader Abou Anas est un demandeur de science (talib ‘ilm) et
								conférencier. Depuis le début des années 2000, le frère consacre la
								majorité de son temps à talab el ‘ilm (l’apprentissage des sciences
								islamiques) et à la da’wah (propagation du message de l’islam). Son
								grand amour pour la religion...{' '}
								<Link
									to="/nader-abou-anas"
									className="text-white underline text-xxs hover:text-gray-300 animate"
								>
									(Lire plus)
								</Link>
							</p>
							<NavSocial size={'medium'} />
						</div>
						<div className="w-full mx-0 md:w-1/3 md:mx-5 lg:mx-28 mt-9">
							<Newsletter />
						</div>
						<div className="w-full md:w-1/3">
							<Coran />
						</div>
					</div>
				</Container>
			</div>

			{/* Copyright */}
			<div
				id="copyright"
				className="flex flex-col items-center justify-between px-5 py-4 text-center text-gray-300 uppercase bg-secondary sm:text-left md:flex-row"
				style={{ fontSize: '10px' }}
			>
				<div>
					© copyright {new Date().getFullYear()}, tous droits réservés - nader abou anas |{' '}
					<Link to="/mentions-legales" className="text-white hover:underline animate">
						Mentions
					</Link>{' '}
					{' | '}
					<Link
						to="/politique-de-confidentialite"
						className="text-white hover:underline animate"
					>
						Politique
					</Link>
				</div>
				<div className="text-right">
					developed by{' '}
					<a
						href="https://arnaud-desportes.fr"
						target="_blank"
						rel="noreferrer"
						className="text-white underline animate"
					>
						REMOTE MONKEY
					</a>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
