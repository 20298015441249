/**
 *  * Rôle : Barre d'icones social de navigation
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NavSocial = (props) => {
	const { size } = props;

	let width;
	let height;
	let fontSize;

	if (size === 'large') {
		width = 'w-10';
		height = 'h-10';
		fontSize = '20px';
	} else if (size === 'medium') {
		width = 'w-7';
		height = 'h-7';
		fontSize = '14px';
	} else {
		width = 'w-5';
		height = 'h-5';
		fontSize = '10px';
	}

	return (
		<div className="flex items-center justify-start text-white">
			<a
				href={`${process.env.GATSBY_FB_SOCIAL_URL}`}
				target="_blank"
				rel="noreferrer"
				className={`hover:border-secondary animate border border-white rounded-full ${width} ${height} flex items-center justify-center`}
				style={{ fontSize: fontSize }}
			>
				<FontAwesomeIcon icon={['fab', 'facebook-f']} />
			</a>
			<a
				href={`${process.env.GATSBY_YTB_SOCIAL_URL}`}
				target="_blank"
				rel="noreferrer"
				className={`hover:border-secondary animate ml-2 border border-white rounded-full ${width} ${height} flex items-center justify-center`}
				style={{ fontSize: fontSize }}
			>
				<FontAwesomeIcon icon={['fab', 'youtube']} />
			</a>
			<a
				href={`${process.env.GATSBY_INSTA_SOCIAL_URL}`}
				target="_blank"
				rel="noreferrer"
				className={`hover:border-secondary animate ml-2 border border-white rounded-full ${width} ${height} flex items-center justify-center`}
				style={{ fontSize: fontSize }}
			>
				<FontAwesomeIcon icon={['fab', 'instagram']} />
			</a>
			<a
				href={`${process.env.GATSBY_TWITTER_SOCIAL_URL}`}
				target="_blank"
				rel="noreferrer"
				className={`hover:border-secondary animate ml-2 border border-white rounded-full ${width} ${height} flex items-center justify-center`}
				style={{ fontSize: fontSize }}
			>
				<FontAwesomeIcon icon={['fab', 'twitter']} />
			</a>
		</div>
	);
};

export default NavSocial;
