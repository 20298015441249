/**
 * Header
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React from 'react';
import PropTypes from 'prop-types';
import flowers from '../../images/svg/flowers-bg.svg';
import Menu from './Menu';
import Container from './Container';
import Banner from './Banner';
import UnderMenu from '../marketing/navigate/UnderMenu';

const HeroTop = (props) => {
	// const { siteTitle } = props ;

	return (
		<>
			<header className="header relative pb-24">
				<div
					className="absolute z-0 w-full h-full"
					style={{
						backgroundImage: `url(${flowers})`,
						backgroundSize: '53%',
						opacity: '0.12'
					}}
				></div>
				<Container className="relative z-20">
					<Menu />
					<Banner />
				</Container>
			</header>
			<Container className="relative z-10" maxWidth={'max-w-2xl'} paddingNull={true}>
				<UnderMenu />
			</Container>
		</>
	);
};

HeroTop.propTypes = {
	siteTitle: PropTypes.string
};

HeroTop.defaultProps = {
	siteTitle: ``
};

export default HeroTop;
